.titleInput {
  font-weight: bold;
}

#title .public-DraftEditorPlaceholder-root {
  font-weight: bold;
}

.subtitleInput {
  font-style: italic;
}

#subtitle .public-DraftEditorPlaceholder-root {
  font-style: italic;
}
